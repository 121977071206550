<template>
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div style="width: 80%; display: flex; justify-content: flex-start;">
                <img src="https://s3iconimages.mymedicine.com.mm/mymedLogo.svg" alt="" style="height: 42px;">
                </div>
                <!-- <v-select
                style="width:40px; border-radius: 40px; "
                :items="LanguageList"
                v-model="Language"
                item-text="item-text"
                item-value="item-value"
                @change="changeLanguage"
                solo
                flat
                >
                <template v-slot:selection="{ item, index }">
                <img :src="item.image" style="border-radius: 50%; height: 20px;">
            </template>
            </v-select> -->
            </div>
</template>

<script>

export default {
  data() {
    return {
      LanguageList: [{"item-text":"English","item-value":"en","image":"https://s3iconimages.mymedicine.com.mm/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg"}],
      Language: ''
    };
  },
  methods: {

    changeLanguage(){
      this.$i18n.locale = this.Language;
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    },

  },
  mounted() {
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
  },

};
</script>

<style scoped>
.v-input >>> .v-text-field__details {
    display: none;
}
.custom-select {
  height: 10px; /* Adjust the height as you need */
}

.custom-select .v-input__control {
  min-height: 10px; /* Ensure internal control matches the height */
  height: 10px;
}

.custom-select .v-select__selections {
  display: flex;
  align-items: center;
  height: 10px; /* Ensure the selection area matches the height */
}
.v-input >>>  .v-input__control {
  min-height: 10px;
  width: 10px;
  /* border-radius: 20px; */
}
.v-input >>>  .v-input__slot {
  border: 1px solid #c6c6c6;
  background: #fff
  /* border-radius: 20px; */
}

.custom-select .v-select__slot {
  min-height: 10px; /* Ensure the input slot has the same height */
}

.v-input >>> .v-text-field__details {
  display: none;
}
</style>
